@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
}

.content {
  margin-left: 300px; /* Adjust this if the sidebar width changes */
  padding: 30px;
}

.icon-button {
  margin-right: 20px; /* Adjust space between hamburger icon and heading */
}

@media (max-width: 600px) {
  .content {
    margin-left: 0; /* Reset margin for mobile view */
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
